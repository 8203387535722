<template>
    <div class="py-12 lg:py-14">
        <div class="container">
            <div class="text-blacks-100 text-[14px] mb-4">Tìm thấy {{ dataSearch?.products?.length }} kết quả cho “{{
                route?.query?.text }}”</div>
            <div class="text-2xl sm:text-35px lg:text-5xl text-blacks-100 font-medium">Kết quả tìm kiếm cho “{{
                route?.query?.text }}”</div>
        </div>
    </div>
    <div class="lg:pt-12 pb-12 lg:pb-24">
        <div class="gap-0 relative flex flex-col md:flex-row items-center md:items-start lg:mx-12.5">
            <div class="w-full relative ml-auto duration-300">
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-5 lg:mx-0">
                    <wrapper-product  v-for="(item, index) in dataSearch?.products" :key="item.id" :item="item" />
                </div>
                <div
                    v-if="checkFilterCount && checkFilterCount > 0 && checkFilterCount >= 9 && checkFilterCount >= LIMIT"
                    @click="showMoreProducts"
                    class="btn-effect-light md:w-full b-1 b-blacks-90 text-lg font-medium py-3 xl:py-5 text-center mt-12 cursor-pointer mx-5 lg:mx-0"
                >
                    Xem thêm
                </div>
                <GlobalLoading v-if="loadingFilter" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import WrapperProduct from '../collection/[slug]/components/WrapperProduct.vue'
const route = useRoute()
const checkFilterCount = ref(0)
const loadingFilter = ref(false)
const LIMIT = ref(9)
const dataSearch = ref();
const { searchProducts } = useProducts();
const res: any = await searchProducts({}, { text: route?.query?.text }, { limit: 9, page: 1 })
dataSearch.value = res
checkFilterCount.value = dataSearch.value.filter_count
const showMoreProducts = async () => {
    const data: any = await searchProducts({}, { text: route?.query?.text }, {
        limit: (LIMIT.value += 9)
    }).catch((err) => console.log(err))

    dataSearch.value = data
    checkFilterCount.value = dataSearch.value.filter_count
}
watch(
    () => route?.query?.text,
    async () => {
        const res = await searchProducts({}, { text: route?.query?.text }, { limit: 9, page: 1 })
        dataSearch.value = res
        checkFilterCount.value = dataSearch.value.filter_count
    },
);
</script>

<style></style>
